var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "margin-dialog-body" } },
    [
      _c(
        "v-card",
        { staticClass: "mb-3" },
        [
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemAviso
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(
            "v-container",
            [
              _c(
                "form",
                { ref: "ofertaForm" },
                [
                  _c("validation-observer", { ref: "observer" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.submit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass:
                              "primary--text font-weight-black justify-center title-2 mt-5"
                          },
                          [_vm._v(" Detalhes de pagamento:")]
                        ),
                        _c(
                          "v-card",
                          { staticClass: "grey lighten-4 ma-3 pa-3" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "ma-auto mt-3",
                                    attrs: { sm: "3", md: "5" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: true,
                                            expression: "true"
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-3 ma-3",
                                                attrs: { shaped: "" }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass:
                                                      "d-flex justify-end align-center"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "primary--text ma-auto pa-n3 ma-4",
                                                        attrs: { size: "100" }
                                                      },
                                                      [_vm._v(" mdi-sale ")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { sm: "12" } },
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "display-1 primary--text font-weight-black"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Quero oferecer uma porcentagem de desconto "
                                                        )
                                                      ]
                                                    ),
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "my-4 display-1 font-weight-black",
                                                      attrs: {
                                                        dense: "",
                                                        solo: "",
                                                        rounded: "",
                                                        disabled:
                                                          _vm.oferta.desconto ||
                                                          _vm.activeIdOffer,
                                                        prefix: "%",
                                                        label: "Digite Aqui"
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.descontoCheckPorcentagem
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.computedPorcentagemDescontoFormated,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.computedPorcentagemDescontoFormated = $$v
                                                        },
                                                        expression:
                                                          "\n                                                            computedPorcentagemDescontoFormated\n                                                        "
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          {
                                            staticClass:
                                              "title-1 grey--text font-weight-regular"
                                          },
                                          [
                                            _vm._v(
                                              " Selecione essa opção para oferecer o desconto em formato de porcentagem (%). Ao selecionar essa opção não será possivel selecionar desconto em valor fixo (R$). "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    false
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "pa-3 ma-3",
                                                attrs: { shaped: "" }
                                              },
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "grey--text ma-auto pa-n3 ma-4",
                                                        attrs: { size: "100" }
                                                      },
                                                      [_vm._v(" report ")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "display-1 grey--text font-weight-light"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Informe o valor do desconto em Reais (R$). "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card-text",
                                                      {
                                                        staticClass:
                                                          "subtitle grey--text font-weight-light"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Preencha os dados ao lado. "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "ma-auto mt-3",
                                    attrs: { sm: "3", md: "5" }
                                  },
                                  [
                                    true
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    staticClass: "pa-3 ma-3",
                                                    attrs: { shaped: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-end align-center"
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "primary--text ma-auto pa-n3 ma-4",
                                                            attrs: {
                                                              size: "100"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-cash-multiple "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "display-1 primary--text font-weight-black"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Quero oferecer um valor fixo total de desconto "
                                                            )
                                                          ]
                                                        ),
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "my-4 display-1 font-weight-black",
                                                          attrs: {
                                                            dense: "",
                                                            solo: "",
                                                            rounded: "",
                                                            disabled:
                                                              _vm.oferta
                                                                .desconto_porcentagem ||
                                                              _vm.activeIdOffer,
                                                            label:
                                                              "Digite Aqui",
                                                            prefix: "R$"
                                                          },
                                                          on: {
                                                            input:
                                                              _vm.descontoCheck
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.computedDescontoFormated,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.computedDescontoFormated = $$v
                                                            },
                                                            expression:
                                                              "\n                                                            computedDescontoFormated\n                                                        "
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "title-1 grey--text font-weight-regular"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Selecione essa opção para oferecer o desconto em formato de valor Fixo (R$). Ao selecionar essa opção não será possivel selecionar desconto em formato porcentagem (%). "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-center" },
                          [
                            !_vm.activeIdOffer
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      rounded: "",
                                      color: "primary",
                                      dark: ""
                                    },
                                    on: { click: _vm.habilitaSecao1 }
                                  },
                                  [_vm._v(" Próximo Passo ")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.passo1,
                                expression: "passo1"
                              }
                            ]
                          },
                          [
                            _c(
                              "v-row",
                              { staticClass: "ma-3 pa-3" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3", md: "6" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "ma-3 pa-6 grey lighten-5"
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "grey--text ml-5",
                                                attrs: { size: "40" }
                                              },
                                              [_vm._v(" credit_card ")]
                                            ),
                                            _c(
                                              "v-card-title",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  " Desejo Oferecer pagamento via Cartão "
                                                )
                                              ]
                                            ),
                                            _c("v-switch", {
                                              staticClass:
                                                "white--text ma-auto",
                                              attrs: {
                                                solo: "",
                                                label: "Ativar",
                                                required: "",
                                                disabled: ""
                                              },
                                              on: { change: _vm.limparCartao },
                                              model: {
                                                value: _vm.oferta.cartao,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.oferta,
                                                    "cartao",
                                                    $$v
                                                  )
                                                },
                                                expression: "oferta.cartao"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.oferta.cartao,
                                                expression: "oferta.cartao"
                                              }
                                            ]
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                solo: "",
                                                prefix: "x",
                                                "error-messages":
                                                  _vm.errorMessages,
                                                label: "Máximo de Parcelas",
                                                type: "number",
                                                disabled:
                                                  !_vm.oferta.cartao ||
                                                  _vm.activeIdOffer,
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.oferta.parcela_cartao,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.oferta,
                                                    "parcela_cartao",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                                    oferta.parcela_cartao\n                                                "
                                              }
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                solo: "",
                                                prefix: "%",
                                                label: "Juros por parcela",
                                                disabled:
                                                  !_vm.oferta.cartao ||
                                                  _vm.activeIdOffer
                                              },
                                              model: {
                                                value:
                                                  _vm.computedJurosCartaoFormated,
                                                callback: function($$v) {
                                                  _vm.computedJurosCartaoFormated = $$v
                                                },
                                                expression:
                                                  "\n                                                    computedJurosCartaoFormated\n                                                "
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      {
                                        staticClass:
                                          "title-1 grey--text font-weight-regular"
                                      },
                                      [
                                        _vm._v(
                                          " Ao ativar essa opção estará habilitando a opção de pagamento via Cartão de Crédito, podendo configurar a quantidade máxima de parcelas e juros. "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "3", md: "6" } },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "ma-3 pa-6 grey lighten-5"
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "primary--text ml-5",
                                                attrs: { size: "40" }
                                              },
                                              [
                                                _vm._v(
                                                  " account_balance_wallet "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-card-title",
                                              { staticClass: "primary--text" },
                                              [
                                                _vm._v(
                                                  " Desejo Oferecer pagamento via Boleto "
                                                )
                                              ]
                                            ),
                                            _c("v-switch", {
                                              staticClass:
                                                "white--text ma-auto",
                                              attrs: {
                                                solo: "",
                                                label: "Ativar",
                                                required: "",
                                                disabled: _vm.activeIdOffer
                                              },
                                              on: { change: _vm.limparBoleto },
                                              model: {
                                                value: _vm.oferta.boleto,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.oferta,
                                                    "boleto",
                                                    $$v
                                                  )
                                                },
                                                expression: "oferta.boleto"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.oferta.boleto,
                                                expression: "oferta.boleto"
                                              }
                                            ]
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                solo: "",
                                                prefix: "x",
                                                "error-messages":
                                                  _vm.errorMessages,
                                                disabled:
                                                  !_vm.oferta.boleto ||
                                                  _vm.activeIdOffer,
                                                label: "Máximo de Parcelas",
                                                type: "number",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.oferta.parcela_boleto,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.oferta,
                                                    "parcela_boleto",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                                                    oferta.parcela_boleto\n                                                "
                                              }
                                            }),
                                            _c("v-text-field", {
                                              attrs: {
                                                solo: "",
                                                prefix: "%",
                                                "error-messages":
                                                  _vm.errorMessages,
                                                disabled:
                                                  !_vm.oferta.boleto ||
                                                  _vm.activeIdOffer,
                                                label: "Juros por boleto",
                                                required: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.computedJurosBoletoFormated,
                                                callback: function($$v) {
                                                  _vm.computedJurosBoletoFormated = $$v
                                                },
                                                expression:
                                                  "\n                                                    computedJurosBoletoFormated\n                                                "
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      {
                                        staticClass:
                                          "title-1 grey--text font-weight-regular"
                                      },
                                      [
                                        _vm._v(
                                          " Ao ativar essa opção estará habilitando a opção de pagamento via Boleto Bancário, podendo configurar a quantidade máxima de parcelas e juros. "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              { staticClass: "justify-center" },
                              [
                                !_vm.activeIdOffer
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          rounded: "",
                                          color: "primary",
                                          dark: ""
                                        },
                                        on: { click: _vm.habilitaSecao2 }
                                      },
                                      [_vm._v(" Próximo Passo ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.passo2,
                                expression: "passo2"
                              }
                            ]
                          },
                          [
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "grey--text font-weight-black justify-center title-2"
                              },
                              [
                                _c("v-icon", [_vm._v(" keyboard_arrow_down ")]),
                                _vm._v(" Passo 3: Definir Prazos da Oferta:")
                              ],
                              1
                            ),
                            _c(
                              "v-card",
                              { staticClass: "ma-6 pa-3 white" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mx-auto my-auto",
                                        attrs: { cols: "8", sm: "12", md: "1" }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "primary--text d-block",
                                            attrs: { size: "100" }
                                          },
                                          [_vm._v(" date_range ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "mx-auto my-auto",
                                        attrs: { cols: "8", sm: "12", md: "4" }
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          {
                                            staticClass:
                                              "primary--text display-1 font-weight-black d-block"
                                          },
                                          [
                                            _vm._v(
                                              " Início e Encerramento da Oferta "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "8", sm: "12", md: "4" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "12",
                                                  md: "12"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    ref: "menu1",
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      "return-value":
                                                        _vm.oferta.data_inicio,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                      "min-width": "290px"
                                                    },
                                                    on: {
                                                      "update:returnValue": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.oferta,
                                                          "data_inicio",
                                                          $event
                                                        )
                                                      },
                                                      "update:return-value": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.oferta,
                                                          "data_inicio",
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function({
                                                          on,
                                                          attrs
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Defina o Início da Oferta",
                                                                      "prepend-icon":
                                                                        "mdi-calendar",
                                                                      readonly:
                                                                        "",
                                                                      clearable:
                                                                        "",
                                                                      disabled:
                                                                        _vm.activeIdOffer
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.computedDateFormattedInicio,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.computedDateFormattedInicio = $$v
                                                                      },
                                                                      expression:
                                                                        "\n                                                                computedDateFormattedInicio\n                                                            "
                                                                    }
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ]),
                                                    model: {
                                                      value: _vm.menu1,
                                                      callback: function($$v) {
                                                        _vm.menu1 = $$v
                                                      },
                                                      expression: "menu1"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-date-picker",
                                                      {
                                                        attrs: {
                                                          "no-title": "",
                                                          scrollable: "",
                                                          locale: "pt-br"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.oferta
                                                              .data_inicio,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.oferta,
                                                              "data_inicio",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                            oferta.data_inicio\n                                                        "
                                                        }
                                                      },
                                                      [
                                                        _c("v-spacer"),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.menu1 = false
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" Cancel ")]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$refs.menu1.save(
                                                                  _vm.oferta
                                                                    .data_inicio
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" OK ")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  sm: "12",
                                                  md: "12"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    ref: "menu2",
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      "return-value":
                                                        _vm.oferta.data_fim,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                      "min-width": "290px"
                                                    },
                                                    on: {
                                                      "update:returnValue": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.oferta,
                                                          "data_fim",
                                                          $event
                                                        )
                                                      },
                                                      "update:return-value": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.oferta,
                                                          "data_fim",
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function({
                                                          on,
                                                          attrs
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Defina o Encerramento",
                                                                      "prepend-icon":
                                                                        "mdi-calendar",
                                                                      readonly:
                                                                        "",
                                                                      "persistent-hint":
                                                                        "",
                                                                      clearable:
                                                                        "",
                                                                      disabled:
                                                                        _vm.activeIdOffer
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.computedDateFormattedFim,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.computedDateFormattedFim = $$v
                                                                      },
                                                                      expression:
                                                                        "\n                                                                computedDateFormattedFim\n                                                            "
                                                                    }
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ]),
                                                    model: {
                                                      value: _vm.menu2,
                                                      callback: function($$v) {
                                                        _vm.menu2 = $$v
                                                      },
                                                      expression: "menu2"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-date-picker",
                                                      {
                                                        attrs: {
                                                          "no-title": "",
                                                          scrollable: "",
                                                          locale: "pt-br"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.oferta.data_fim,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.oferta,
                                                              "data_fim",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "\n                                                            oferta.data_fim\n                                                        "
                                                        }
                                                      },
                                                      [
                                                        _c("v-spacer"),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.menu2 = false
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" Cancelar ")]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.$refs.menu2.save(
                                                                  _vm.oferta
                                                                    .data_fim
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [_vm._v(" OK ")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("v-col", {
                                      staticClass: "mx-auto my-auto",
                                      attrs: { cols: "12", sm: "3", md: "3" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-card-title",
                              {
                                staticClass:
                                  "grey--text font-weight-black justify-center title-2"
                              },
                              [
                                _c("v-icon", [_vm._v(" keyboard_arrow_down ")]),
                                _vm._v(" Passo 4: Ative a Renegociação:")
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "mx-auto my-auto",
                                attrs: { cols: "12", sm: "3", md: "12" }
                              },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "ma-3 pa-3 green" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "mx-auto my-auto" },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { sm: "3", md: "8" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "title-2 font-weight-black white--text"
                                              },
                                              [
                                                _vm._v(" Status da Oferta"),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: { size: "40" }
                                                  },
                                                  [_vm._v(" double_arrow ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "mx-auto my-auto",
                                            attrs: { sm: "3", md: "4" }
                                          },
                                          [
                                            !_vm.activeIdOffer
                                              ? _c(
                                                  "v-card",
                                                  { staticClass: "ma-2 pa-2" },
                                                  [
                                                    !_vm.activeIdOffer
                                                      ? _c("v-switch", {
                                                          staticClass:
                                                            "mx-auto my-auto",
                                                          attrs: {
                                                            color: "success",
                                                            inset: "",
                                                            label:
                                                              "Desativar/Ativar Oferta",
                                                            required: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.oferta.status,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.oferta,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "oferta.status"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.activeIdOffer
                                              ? _c(
                                                  "v-card",
                                                  { staticClass: "ma-2 pa-2" },
                                                  [
                                                    _vm.activeIdOffer
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "error",
                                                              width: "100%"
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.modalDesabilitaOferta
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Desabilitar Oferta "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm.modalTemCerteza
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "800px",
                        scrollable: "",
                        color: "background",
                        transition: "dialog-bottom-transition"
                      },
                      model: {
                        value: _vm.modalTemCerteza,
                        callback: function($$v) {
                          _vm.modalTemCerteza = $$v
                        },
                        expression: "modalTemCerteza"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pa-0" },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    flat: "",
                                    dark: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", dark: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.modalTeste = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  ),
                                  _c("v-toolbar-title", [
                                    _vm._v(" Desabilitar Oferta ")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "px-8 py-4" },
                            [
                              _c(
                                "v-row",
                                { staticClass: "d-flex text-center" },
                                [
                                  _c("v-col", { attrs: { lg: "2" } }),
                                  _c(
                                    "v-col",
                                    { attrs: { lg: "8" } },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mt-5",
                                              attrs: {
                                                color: "primary",
                                                size: "500%"
                                              }
                                            },
                                            [_vm._v(" mdi-information ")]
                                          ),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass: "title primary--text"
                                            },
                                            [
                                              _vm._v(
                                                " Tem certeza que deseja desabilitar? "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-card-text",
                                            { staticClass: "title" },
                                            [
                                              _vm._v(
                                                " Ao desabilitar a oferta, os devedores não poderão mais utilizar ela para realizar pagamentos de títulos. "
                                              )
                                            ]
                                          ),
                                          _c("v-card-text", [
                                            _vm._v(
                                              " Em caso de dúvidas, entre em contato com nosso suporte através do e-mail: suporte@recuperi.com.br "
                                            )
                                          ]),
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "d-flex justify-center",
                                              staticStyle: {
                                                display: "flex",
                                                "align-content": "center",
                                                "justify-content": "center",
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label: `Li e concordo com as condições acima.`
                                                },
                                                model: {
                                                  value: _vm.liConcordo,
                                                  callback: function($$v) {
                                                    _vm.liConcordo = $$v
                                                  },
                                                  expression: "liConcordo"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { lg: "2" } })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "end",
                                padding: "8px 48px 8px 16px"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-5",
                                  attrs: {
                                    color: "success white--text",
                                    width: "120",
                                    loading: _vm.loading,
                                    disabled: !_vm.liConcordo
                                  },
                                  on: { click: _vm.desabilitarOferta }
                                },
                                [_vm._v(" Confirmar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-5",
                                  attrs: {
                                    color: "grey white--text",
                                    width: "120",
                                    loading: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.modalTemCerteza = false
                                    }
                                  }
                                },
                                [_vm._v(" Cancelar ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }